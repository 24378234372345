export const DirectionalityService = {
  getRightToLeftLanguageList(): string[] {
    return (process.env.GATSBY_RTL_LANGUAGE_CODES || '')
      .split(',')
      .map((code) => code.trim().toLowerCase());
  },
  /**
   *
   * @param languageCode
   * @returns The value for the languages html dir attribute
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/dir
   */
  getDirectionality(languageCode: string): 'ltr' | 'rtl' {
    return DirectionalityService
      .getRightToLeftLanguageList()
      .includes(languageCode?.trim().toLowerCase())
      ? 'rtl'
      : 'ltr';
  },
};
