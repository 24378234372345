import { getGlobalConfigElement } from '../../utils/get-global-config-element';
import { retry } from '../../utils/retry';
import {
  TeaserService, TeaserItem, TeaserItems, Query,
} from '../teaser';

interface StoryTeaserContent {
  tag: string[];
  tag_group: string[];
  teaser_title?: string;
  teaser_type?: string;
  teaser_description?: string;
  teaser_image?: {
    src: {
      filename: string;
    };
  };
}

export type StoryItem = TeaserItem<StoryTeaserContent>;
export type StoryItems = TeaserItems<StoryTeaserContent>;

interface StoryTeaserQueryResult {
  data: {
    StoryItem?: StoryItem | null;
    StoryItems?: StoryItems;
  };
}

type ParsedQueryResult = StoryItem | StoryItems;

export const StoryTeaserService = {
  async getStoryTeaser(teaserId: number): Promise<StoryItem> {
    const fetchTeaser = () => fetch(
      TeaserService.settings.options.url,
      TeaserService.createRequest(this.createStoryTeaserByIdQuery(teaserId)),
    )
      .then((response) => response.json())
      .then((result) => this.parseQueryResults(result));

    return retry(fetchTeaser);
  },

  async getStoryTeasers(
    tags: string[],
    groupTags: string[] = [],
    sortBy: string,
    page: number,
    perPage: number,
  ): Promise<StoryItems> {
    return tags.length > 0 && groupTags.length > 0
      ? TeaserService.fetchByAnd(
        tags,
        groupTags,
        sortBy,
        page,
        perPage,
        this.createStoryTeaserByTypeQuery.bind(this),
        this.parseQueryResults.bind(this),
      )
      : TeaserService.fetchByOr(
        tags,
        groupTags,
        sortBy,
        page,
        perPage,
        this.createStoryTeaserByTypeQuery.bind(this),
        this.parseQueryResults.bind(this),
      );
  },

  parseQueryResults(queryResult: StoryTeaserQueryResult): ParsedQueryResult {
    const {
      StoryItem, StoryItems,
    } = queryResult.data || {};

    if (!StoryItems) {
      return {
        ...(StoryItem || null),
      };
    }

    return {
      items: [...StoryItems.items],
      total: StoryItems.total,
    };
  },

  createStoryTeaserByTypeQuery(
    tags: string[],
    groupTags: string[],
    sortBy: string,
    page: number,
    perPage: number,
  ): Query {
    const storyblokStoryId = getGlobalConfigElement()?.dataset.storyblokStoryId || '';
    const parameters = `${TeaserService.createTagQueryParameter(tags, groupTags)} sort_by: "${sortBy}", page: ${page}, per_page: ${perPage}, excluding_ids: "${storyblokStoryId}" ,${TeaserService.createLanguageQueryParameter()}`;

    return {
      query: `{
        StoryItems(${parameters}) {
          items {
            content {
              tag
              tag_group
              teaser_title
              teaser_type
              teaser_description
              teaser_image
            }
            published_at
            default_full_slug
            id
            first_published_at
          }
          total
        }
      }`,
    };
  },

  createStoryTeaserByIdQuery(teaserId: number): Query {
    const parameters = `id: ${teaserId}`;
    return {
      query: `{
        StoryItem(${parameters}) {
          full_slug
          content {
            tag
            tag_group
            teaser_title
            teaser_type
            teaser_description
            teaser_image
          }
          published_at
          first_published_at
        }
      }`,
    };
  },
};
