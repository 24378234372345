import { getGlobalConfigElement } from '../../utils/get-global-config-element';
import { retry } from '../../utils/retry';
import { LanguageService } from '../language';
import { StoryblokService } from '../storyblok';

export interface Query {
  query: string;
}

// Generic teaser item parameterized by its content.
export interface TeaserItem<Content> {
  content: Content;
  default_full_slug?: string;
  full_slug: string;
  id: number;
  first_published_at: string;
}

// Multiple generic teaser items
export interface TeaserItems<Content> {
  idList?: number[];
  items: TeaserItem<Content>[];
  total: number;
}

interface DefaultTeaserContent {
  tag: string[];
  tag_group: string[];
  teaser_title?: string;
  teaser_overline?: string;
  teaser_description?: string;
  teaser_image?: {
    src: {
      filename: string;
    };
  };
  secondary_action?: {
    href: {
      story: {
        full_slug: string;
      }
      url: string;
    }
  }
}

export type PageItem = TeaserItem<DefaultTeaserContent>;
export type PageItems = TeaserItems<DefaultTeaserContent>;

interface TeaserQueryResult {
  data: {
    PageItem?: PageItem | null;
    PageItems?: PageItems;
  };
}

type ParsedQueryResult = PageItem | PageItems;

export const TeaserService = {
  settings: {
    ...StoryblokService.getConfig(),
  },

  nonDefaultThemeSubcategories: ['events', 'life-talks'],

  async getTeaser(teaserId: number) {
    const fetchTeaser: () => Promise<PageItem> = () => fetch(
      this.settings.options.url,
      this.createRequest(this.createTeaserByIdQuery(teaserId)),
    )
      .then((response) => response.json())
      .then((result) => this.parseQueryResults(result));

    return retry(fetchTeaser);
  },

  async getTeasers(
    tags: string[],
    groupTags: string[] = [],
    sortBy: string,
    page: number,
    perPage: number,
  ): Promise<PageItems> {
    return tags.length > 0 && groupTags.length > 0
      ? this.fetchByAnd(
        tags,
        groupTags,
        sortBy,
        page,
        perPage,
        this.createTeaserByTypeQuery.bind(this),
        this.parseQueryResults.bind(this),
      )
      : this.fetchByOr(
        tags,
        groupTags,
        sortBy,
        page,
        perPage,
        this.createTeaserByTypeQuery.bind(this),
        this.parseQueryResults.bind(this),
      );
  },

  // Get items with "Tag 1" OR "Tag 2"
  async fetchByOr<Content, QueryResult>(
    tags: string[],
    groupTags: string[],
    sortBy: string,
    page: number,
    perPage: number,
    createQuery:
    (tags: string[], groupTags: string[], sortBy: string, page: number, perPage: number) => Query,
    parseResult: (queryResult: QueryResult) => TeaserItems<Content>,
  ): Promise<TeaserItems<Content>> {
    const fetchTeasers = () => fetch(
      this.settings.options.url,
      this.createRequest(createQuery(tags, groupTags, sortBy, page, perPage)),
    )
      .then((response) => response.json())
      .then((result) => parseResult(result));

    return retry(fetchTeasers);
  },

  // Get items with "Tag 1" AND "Tag 2"
  async fetchByAnd<Content, QueryResult>(
    tags: string[],
    groupTags: string[],
    sortBy: string,
    page: number,
    perPage: number,
    createQuery:
    (tags: string[], groupTags: string[], sortBy: string, page: number, perPage: number) => Query,
    parseResult: (queryResult: QueryResult) => TeaserItems<Content>,
  ): Promise<TeaserItems<Content>> {
    /*
     * When there's a group tag, we must create a separate request for each entry in "tags"
     * in conjuntion with each entry of "groupTags".
     * We then return all the response datasets merged, removing duplicates and updating the total.
     */
    const allQueries = groupTags
      .flatMap(
        (groupTag) => tags.map((tag) => async (): Promise<TeaserItems<Content>> => {
          const fetchTeasers = () => fetch(
            this.settings.options.url,
            // eslint-disable-next-line max-len
            this.createRequest(createQuery([tag], [groupTag], sortBy, page, perPage)),
          )
            .then((response) => response.json())
            .then((result) => parseResult(result));

          return retry(fetchTeasers);
        }),
      );

    const responses = await Promise.all(allQueries.map((query) => query()));
    const mergedResponse = responses
      .reduce(
        (accumulator, response) => {
          const { items } = response;
          const uniqueItems = items.filter((item) => !accumulator.idList.includes(item?.id));
          const duplicatedItems = items.filter((item) => accumulator.idList.includes(item?.id));
          const mergedTotal = accumulator.total + response.total - duplicatedItems.length;

          return {
            idList: [...accumulator.idList, ...uniqueItems.map((item) => item.id)],
            items: [...accumulator.items, ...uniqueItems],
            total: mergedTotal,
          };
        },
        { idList: [], items: [], total: 0 },
      );

    return mergedResponse;
  },

  parseQueryResults(queryResult: TeaserQueryResult): ParsedQueryResult {
    const {
      PageItem, PageItems,
    } = queryResult.data;

    if (!PageItems) {
      return {
        ...(PageItem || null),
      };
    }

    return {
      items: [...PageItems.items],
      total: PageItems.total,
    };
  },

  createRequestHeaders(): HeadersInit {
    const { options: { accessToken } } = this.settings;

    return {
      token: accessToken as string,
      version: 'published',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  },

  createRequest(query: { query: string }): RequestInit {
    return {
      method: 'POST',
      mode: 'cors',
      headers: this.createRequestHeaders(),
      body: JSON.stringify(query),
    };
  },

  createLanguageQueryParameter(): string {
    if (!LanguageService.isPageDefaultLanguage()) {
      return `starts_with: "${LanguageService.getActiveLanguage()}/"`;
    }

    // TODO: We can't use component library's DomService due to clashing libraries and polyfills
    const globalConfigurationElement = getGlobalConfigElement();
    const allSpaceLocales = globalConfigurationElement?.dataset.allSpaceLocales || '[]';

    const excludeList = JSON.parse(allSpaceLocales)
      .filter((locale) => locale !== LanguageService.getDefaultLocale())
      .map((locale) => `${locale}/*`);

    return `excluding_slugs: "${excludeList.join(',')}"`;
  },

  createTagQueryParameter(tags: string[], groupTags: string[]): string {
    const tagQuery = tags.length > 0
      ? `tag: {in_array: "${tags.join(',')}"},`
      : '';
    const groupTagQuery = tags.length > 0
      ? `tag_group: {all_in_array: "${groupTags.join(',')}"}`
      : `tag_group: {in_array: "${groupTags.join(',')}"}`;

    return `filter_query_v2: {${tagQuery} ${groupTagQuery} },`;
  },

  createTeaserByTypeQuery(
    tags: string[],
    groupTags: string[],
    sortBy: string,
    page: number,
    perPage: number,
  ): Query {
    const storyblokStoryId = getGlobalConfigElement()?.dataset.storyblokStoryId || '';
    const parameters = `${this.createTagQueryParameter(tags, groupTags)} sort_by: "${sortBy}", page: ${page}, per_page: ${perPage}, excluding_ids: "${storyblokStoryId}", ${this.createLanguageQueryParameter()}`;

    return {
      query: `{
        PageItems(${parameters}) {
          items {
            content {
              tag
              tag_group
              teaser_title
              teaser_overline
              teaser_description
              teaser_image
            }
            published_at
            default_full_slug
            id
            first_published_at
          }
          total
        }
      }`,
    };
  },

  createTeaserByIdQuery(teaserId: number): Query {
    const parameters = `id: ${teaserId}`;
    return {
      query: `{
        PageItem(${parameters}) {
          full_slug
          content {
            tag
            tag_group
            teaser_title
            teaser_overline
            teaser_description
            teaser_image
          }
        }
      }`,
    };
  },
};
