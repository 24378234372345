export const ConsoleService = {
  isStencilWarning(...args): boolean {
    return (
      args[0]?.startsWith('STENCIL')
    );
  },

  /**
   * A whitelist of errors and warnings that should not be printed to the
   * console if the verbosity level is set to `no-whitelist`.  Only include
   * errors and warnings that are harmless or outside of our control. Each
   * predicate in a list takes the same arguments as the original console
   * method.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get whitelist(): Record<string, Array<(...args: any[]) => boolean>> {
    return {
      warn: [this.isStencilWarning],
      error: [],
    };
  },

  /**
   * Control the verbosity of the console output.
   */
  setVerbosity(verbosity: 'quiet' | 'no-whitelist' | 'default'): void {
    if (verbosity === 'default') {
      return;
    }

    // Suppress all warnings and errors
    if (verbosity === 'quiet') {
      ['warn', 'error'].forEach((method) => this.adjustConsoleMethod(method, () => true));
    }

    // Do not print warnings and errors that are whitelisted
    if (verbosity === 'no-whitelist') {
      Object.entries(this.whitelist)
        .forEach(([method, predicates]) => this.adjustConsoleMethod(method, (...args) => (
          (predicates as Array<(...sameAsArgs) => boolean>).some((predicate) => predicate(...args))
        )));
    }
  },

  /**
   * Adjust a console `method` such that messages for which the `predicate`
   * returns `true` are not printed. The `predicate` takes the same arguments as
   * the original console method.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adjustConsoleMethod(method: string, predicate: (...args: any[]) => boolean) {
    // eslint-disable-next-line no-console
    const originalConsoleMethod = console[method];

    // eslint-disable-next-line no-console
    console[method] = (...args) => {
      if (predicate(...args)) {
        return;
      }

      originalConsoleMethod(...args);
    };
  },
};
