// eslint-disable-next-line max-classes-per-file
import { Story } from 'storyblok-js-client';

const root: string = process.env.GATSBY_CONTENT_API_DOMAIN;
const endpoints = {
  content: '/content',
  story: '/content/story',
} as const;
const website: string = process.env.GATSBY_WEBSITE_NAME;
const version: ContentVersion = process.env.GATSBY_ENV as ContentVersion;
const token = process.env.GATSBY_STORYBLOK_SPACE_API_KEY;

type ContentVersion = 'preview' | 'live';
type Endpoints = typeof endpoints;
type ValueOf<T> = T[keyof T];

export class ContentService {
  public getStory = async (
    storyId: string,
    params?: RequestInit,
    contentVersion?: ContentVersion,
  ): Promise<Story> => this.get(`${this.getRequestUrl(endpoints.content, contentVersion)}&id=${storyId}`, params);

  private getTokenParam = (contentVersion?: ContentVersion): string => {
    /** NOTE
     * We check only for live.
     * If you pass 'preview', we rely on the environment having access to draft content.
     * This prevents developers misusing the service and leaking non published content.
     */
    if (contentVersion === 'live') {
      return '';
    }
    return version === 'preview' ? `&token=${token}` : '';
  };

  private getRequestUrl = (
    endpoint: ValueOf<Endpoints>,
    contentVersion?: ContentVersion,
  ): string => `${root}${endpoint}?website=${website}&version=${contentVersion || version}${this.getTokenParam(contentVersion)}`;

  private get = <T>(url: string, params?: RequestInit): T => fetch(url, params)
    .then(async (r) => {
      const response = await r.json();
      if (!r.ok) {
        throw new Error(response.message);
      }
      return response;
    }) as T;
}
