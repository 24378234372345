const supportsWebPKey = Symbol.for('isWebPSupported');

export const BrowserDetectionService = {
  async detectWebPSupport(): Promise<void> {
    const image = new Image();
    image.src = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
    try {
      await image.decode();
      window[supportsWebPKey] = (image.width > 0) && (image.height > 0);
    } catch (error) {
      window[supportsWebPKey] = false;
    }
  },
  isWebPSupported(): boolean {
    return this.isBrowserContext()
      ? window[supportsWebPKey]
      : true;
  },
  isBrowserContext(): boolean {
    return typeof window !== 'undefined';
  },
};
