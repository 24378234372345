import { IPluginRefObject } from 'gatsby';
import StoryblokClient, { StoryblokResult } from 'storyblok-js-client';
import { storyblokConfig } from '../../../configuration/storyblok-config';
import { BrowserDetectionService } from '../browser-detection';

export interface WindowWithStoryblokBridge {
  StoryblokBridge?: StoryblokBridge;
}

export interface StoryblokDatasourceResult extends StoryblokResult {
  slug: string,
  params: Record<string, unknown>;
  page: number,
}

export interface StoryblokDimension {
  id: string,
  name: string,
  entry_value: string,
  datasource_id: number,
  created_at: string,
  updated_at: string,
}

export interface APIConnectorPluginSettings extends IPluginRefObject {
  resolve: string;
  options: {
    accessToken: string;
    typeName: string;
    fieldName: string;
    url: string;
    headers: {
      Token: string;
      Version: string;
    };
    resolveLinks?: 'url' | 'story';
    resolveRelations?: string;
  };
}

const getUrlParams = (): Record<string, string | true> => window.location.search.substr(1)
  .split('&')
  .filter((slug) => !!slug)
  .reduce((accumulator, slug) => ({
    ...accumulator,
    ...(slug.split('=')[0] && {
      [slug.split('=')[0]]: slug.split('=')[1] || true,
    }),
  }), {});

export const StoryblokService = {
  getConfig(): APIConnectorPluginSettings {
    return (storyblokConfig || {}) as APIConnectorPluginSettings;
  },

  getObject(): StoryblokBridge {
    return BrowserDetectionService.isBrowserContext()
      ? (window as WindowWithStoryblokBridge).StoryblokBridge
      : undefined;
  },

  async getDatasources(
    client: StoryblokClient,
    slugs: string[],
    params?: Record<string, string | number>,
  ): Promise<StoryblokResult[]> {
    const initialResponses = await Promise.all(
      slugs.map((slug) => this.createDatasourceEntriesRequest(client, slug, 1, params)),
    );

    const additionalRequests = [];
    initialResponses
      .forEach((datasource) => {
        if (datasource.total <= datasource.perPage) {
          return;
        }
        const totalPages = datasource.total / datasource.perPage;

        for (let page = 1; page < totalPages; page += 1) {
          additionalRequests
            .push(this.createDatasourceEntriesRequest(client, datasource.slug, page + 1, params));
        }
      });

    if (additionalRequests.length === 0) {
      return initialResponses;
    }

    const additionalResponses = await Promise.all(additionalRequests);

    return this.combineDatasourceResponses(initialResponses, additionalResponses);
  },

  async getDatasourceDimensionNames(
    client: StoryblokClient,
    slug: string,
  ): Promise<StoryblokDimension[]> {
    const datasources = await this.createDatasourceRequest(client);

    return datasources.data.datasources.filter(
      (datasource) => datasource.slug === slug,
    )[0]?.dimensions;
  },

  createDatasourceEntriesRequest(
    client: StoryblokClient,
    slug: string,
    page: number,
    params?: Record<string, string | number>,
  ): Promise<StoryblokDatasourceResult> {
    return client.get('cdn/datasource_entries', {
      datasource: slug,
      per_page: 100,
      page,
      ...params,
    }).then((response) => ({
      ...response,
      slug,
      params,
      page,
    }));
  },

  createDatasourceRequest(
    client: StoryblokClient,
    params?: Record<string, string | number>,
  ): Promise<StoryblokResult> {
    return client.get('cdn/datasources', {
      per_page: 100,
      ...params,
    });
  },

  /**
   * TODO:
   * refactor this method to use a single argument combineDatasourceResponses(datasourcesResponses)
   * Discussion doc
   * [{1, data}, {2, data}, {1, data}, {1, data},] ->
   *  {}
   *  {
   *   1: [data]
   *   2: [data]
   *  }
   * return [1, [data], [2, [data]]]
   *
   */
  combineDatasourceResponses(
    allDatasources: StoryblokDatasourceResult[],
    newDatasources: StoryblokDatasourceResult[],
  ): StoryblokDatasourceResult[] {
    newDatasources.forEach((datasource) => {
      const firstDatasource = allDatasources.find(
        (searchDatasource) => searchDatasource.slug === datasource.slug
        && searchDatasource.params === datasource.params,
      );
      firstDatasource.data.datasource_entries = firstDatasource.data?.datasource_entries.concat(
        datasource.data?.datasource_entries);
      firstDatasource.perPage += datasource.perPage;
    });
    return allDatasources;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async redirect(callback?: (...args: any[]) => void): Promise<void> {
    return new Promise((resolve) => {
      const { redirect, path } = getUrlParams();

      const isValidRedirect = typeof redirect === 'string';

      if (!redirect) {
        resolve();
        return;
      }

      if (!StoryblokService.getObject()) {
        const queryParameters = path ? `?path=${path}` : '';
        window.location.href = isValidRedirect ? `${redirect}${queryParameters}` : '/';
        resolve();
        return;
      }

      const storyblokClient = new StoryblokClient({
        accessToken: StoryblokService.getConfig().options.accessToken as string,
      });

      storyblokClient.getStory((redirect as string))
        .then((...args) => {
          if (callback) {
            callback(...args);
          }
          resolve();
        });
    });
  },

  isPreviewContent: (): boolean => StoryblokService.getConfig().options.headers.Version === 'draft',

  isInEditor: (): boolean => !!StoryblokService.getObject(),
};
