import { Dispatch, SetStateAction } from 'react';
import Storyblok from 'storyblok-js-client';
import { LanguageService } from '../language';
import { NavigationService } from '../navigation';

type SetState = Dispatch<SetStateAction<unknown>>;

export const EditorService = {
  loadStoryblokBridge: (onLoadHandler: EventListener): void => {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://app.storyblok.com/f/storyblok-v2-latest.js');
    script.onload = onLoadHandler;
    document.head.appendChild(script);
  },

  async loadBreadcrumbs(path: string, callback: SetState): Promise<void> {
    const breadcrumbs = await NavigationService.getBreadcrumbsFromPath(path);
    callback({ breadcrumbs });
  },

  async loadFontOverwrites(client: Storyblok) {
    const defaultLocale = LanguageService.getDefaultLocale();
    const locale = LanguageService.getActiveLanguage();
    const hasDimension = locale !== defaultLocale;

    const fontOverwrites = await client.get('cdn/datasource_entries', {
      datasource: 'alternate-fonts',
      page: 1,
      per_page: 20,
      dimension: hasDimension ? `${locale}` : '',
    });

    const overwrites = (fontOverwrites).data.datasource_entries || [];

    if (overwrites.length <= 0) {
      return;
    }

    const fontFaceOverwrites = overwrites
      .reduce((accumulator, entry) => {
        const value = hasDimension ? entry.dimension_value : entry.value;
        if (!value || value.trim() === 'default') {
          return accumulator;
        }

        return {
          ...accumulator,
          [entry.name]: value,
        };
      }, {});

    // eslint-disable-next-line consistent-return
    return fontFaceOverwrites;
  },

  async loadGlobalComponents(client: Storyblok, callback: SetState): Promise<void> {
    const queryParams = {
      filter_query: {
        component: {
          in: 'global-components',
        },
      },
    };

    const globalComponents = await client.getAll('cdn/stories', queryParams);

    if (globalComponents.length <= 0) {
      // eslint-disable-next-line no-alert
      alert('Your website will break because no "Global Components" were found. Please warn your adminstrator.');
      return;
    }

    if (globalComponents.length > 1) {
      // eslint-disable-next-line no-alert
      alert('More than 1 instance of Global Components found. Your website will break! Please warn your adminstrator.');
    }

    callback({ globalComponents: globalComponents[0] });
  },

  async loadGlobalComponent(
    uuid: string,
    stateKeyName: string,
    client: Storyblok,
    callback: SetState,
    params = {},
    locale,
  ): Promise<void> {
    if (!uuid) {
      return;
    }

    const queryParams = {
      ...params,
      by_uuids: uuid,
    };

    const [component] = await client.getAll('cdn/stories', queryParams);
    const alternate = component?.alternates
      .find(({ full_slug: slug }) => locale === LanguageService.getLocaleFromSlug(slug));

    if (!alternate || locale === LanguageService.getDefaultLocale()) {
      callback({ [stateKeyName]: component });
      return;
    }

    const alternateComponent = await client.get(`cdn/stories/${alternate.full_slug}`, { ...params });
    callback({ [stateKeyName]: alternateComponent.data?.story });
  },

  async loadDefaultLanguageHome(
    uuid: string,
    stateKeyName: string,
    client: Storyblok,
    callback: SetState,
    params = {},
  ): Promise<void> {
    if (!uuid) {
      return;
    }

    const queryParams = {
      ...params,
      by_uuids: uuid,
    };

    const [component] = await client.getAll('cdn/stories', queryParams);
    callback({ [stateKeyName]: component });
  },
};
