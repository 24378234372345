import { sleep } from './sleep';

const exponentialBackoff = async <T>(
  requestFunction: () => Promise<T>,
  maxRetries: number,
  minRetryIntervalMs: number,
  retries: number,
  error: Error,
): Promise<T> => {
  if (retries > maxRetries) throw error;

  try {
    return await requestFunction();
  } catch (err) {
    // 100ms, 200ms, 400ms, 800ms, 1600ms, ... + random between 0ms - 100ms
    const backoffTime = 2 ** retries * minRetryIntervalMs + Math.random() * 100;
    await sleep(backoffTime);
    return exponentialBackoff(requestFunction, maxRetries, minRetryIntervalMs, retries + 1, err);
  }
};

/**
 * Retries the given function until it successfully resolves
 * @param requestFunction function to be retried
 * @param maxRetries maximum number of retry attempts
 * @param maxRetries maximum number of retry attempts
 * @param minRetryIntervalMs minimum time interval between retries in miliseconds
 * @returns result of the provided function if successful
 * @throws error if maxRetries are exceeded
 */
export const retry = <T>(
  requestFunction: () => Promise<T>,
  maxRetries = 5,
  minRetryIntervalMs = 100,
) => exponentialBackoff(requestFunction, maxRetries, minRetryIntervalMs, 0, null);
