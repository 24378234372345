import { ClientLocationResponse } from './types';

export const ClientLocation = {
  async get(): Promise<ClientLocationResponse> {
    const apiResponse = await fetch(process.env.GATSBY_CLIENT_LOCATION_API_URL)
      .then((response) => response.json())
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
    return apiResponse || {};
  },
};
