import StoryblokClient from 'storyblok-js-client';
import { StoryDataFromGraphQLQuery } from '../../templates/types';
import { StoryblokService } from '../storyblok';

const retry = <T>(
  fn: () => Promise<T>,
  delay: number,
  retries: number,
  conditionFn: (error) => boolean,
): Promise<T> => new Promise((resolve) => {
  fn()
    .then((response) => {
      if (conditionFn(response) && retries > 0) {
        setTimeout(() => {
          resolve(retry(fn, delay, retries - 1, conditionFn));
        }, delay);
      } else {
        resolve(response);
      }
    });
});

// https://www.storyblok.com/docs/graphql-api#rate-limits
const is429Error = (resp): boolean => resp.errors?.[0]?.message?.includes('429');

export const BuildService = {
  client: new StoryblokClient({
    accessToken: StoryblokService.getConfig().options.accessToken as string,
  }),
  /* eslint-disable @typescript-eslint/no-explicit-any */
  getGlobalComponents: (stories: Record<string, any>): StoryDataFromGraphQLQuery => {
    const globalComponents = stories.find(((story: any) => story?.content?.component === 'global-components'));

    if (!globalComponents || globalComponents.length <= 0) {
      throw new Error('Global components not defined. Please refer to https://roche-ds.atlassian.net/wiki/spaces/RWI21/pages/2113536007/Website+configuration for details');
    }

    if (globalComponents.length > 1) {
      throw new Error('More than 1 instance of global components is defined. Only 1 is allowed per space.');
    }

    return globalComponents;
  },
  /* eslint-enable @typescript-eslint/no-explicit-any */

  getGlobalComponent: (
    list: StoryDataFromGraphQLQuery[],
    uuid: string,
    activeLanguage: string,
    defaultLanguage: string,
  ): StoryDataFromGraphQLQuery => {
    const defaultStory = list.find(({ uuid: id }) => id === uuid);

    if (activeLanguage === defaultLanguage || !defaultStory) {
      return defaultStory;
    }

    const { alternates } = defaultStory;
    const availableAlternates = alternates
      .reduce(
        (accumulator, value) => {
          if (value) {
            // StoryData type is incorrect from storyblok-js-client
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accumulator[(value as any).fullSlug.split('/')[0]] = value.id;
          }
          return accumulator;
        },
        {},
      );

    return list.find(({ id }) => id === availableAlternates[activeLanguage]) || defaultStory;
  },

  queryPrepareContentFetch: `
    {
      storyblok {
        ContentNodes(per_page: 1) {
          total
        }
      }
    }
  `,

  queryContent: (
    page = 1,
    perPage = 100,
    resolveRelations = StoryblokService.getConfig().options.resolveRelations,
    resolvelinks = StoryblokService.getConfig().options.resolveLinks,
  ) => {
    const queryResolveRelations = resolveRelations ? `resolve_relations: "${resolveRelations}",` : '';
    const queryResolveLinks = resolvelinks ? `resolve_links: "${resolvelinks}",` : '';

    return `
      {
        storyblok {
          ContentNodes(page: ${page}, per_page: ${perPage}, ${queryResolveLinks} ${queryResolveRelations}) {
            items {
              alternates {
                fullSlug
                id
                slug
                published
              }
              id
              name
              created_at
              uuid
              slug
              full_slug
              is_startpage
              parent_id
              group_id
              position
              tag_list
              content
              first_published_at
            }
            total
          }
        }
      }
    `;
  },

  /*
   * Returns stories, divided by language, to facilitate consumption
   * {
   *  LanguageService.defaultLanguage: [all non locale prefixed path stories],
   *  lang1: [all lang1 locale prefixed path stories],
   *  lang2: [all lang2 locale prefixed path stories],
   *  etc
   * }
   */
  parseStoriesByLanguage: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    array: any[],
    languageDetector: (str: string) => string,
  ) => array.reduce((acc, val) => {
    if (val) {
      const langFromSlug = languageDetector(val.full_slug);

      return {
        ...acc,
        [langFromSlug]: [...(acc[langFromSlug] || []), { ...val }],
      };
    }
    return acc;
  }, {}),

  createQuery: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graphql: (query: string) => Promise<any>,
    container: unknown[],
    totalItems: number,
    itemsPerPage: number,
  ) => {
    const lastPage = Math.ceil((totalItems / itemsPerPage));
    const allPageQueries = Array.from({ length: lastPage }, (_, i) => i + 1);

    return allPageQueries.map((page) => async () => {
      const {
        data,
        errors,
      } = await retry(() => graphql(BuildService.queryContent(page, itemsPerPage)),
        1000,
        10,
        is429Error);

      if (errors) {
        throw new Error(errors);
      }

      if (!Array.isArray(data.storyblok.ContentNodes.items)) {
        throw new Error('ERROR: GraphQL call has no data.');
      }

      const { items } = data.storyblok.ContentNodes;
      container.push(...(items as never[]));
    });
  },

  async getFontFaceOverwrites(
    graphql: (string) => Promise<Record<string, unknown>>,
    defaultLocale: string,
    localeList: string[],
  ): Promise<Record<string, Record<string, string>>> {
    const fontFaceOverwrites = {};
    const queryList = localeList
      .map((locale) => async () => {
        const hasDimension = locale !== defaultLocale;
        const queryString = `{
          storyblok {
            DatasourceEntries(datasource: "alternate-fonts", page: 1, per_page: 20 ${hasDimension ? `, dimension: "${locale}"` : ''}) {
              items {
                dimensionValue
                value
                name
              }
            }
          }
        }`;
        const { data, errors } = await retry(
          () => graphql(queryString),
          1000,
          10,
          is429Error,
        );

        if (errors) {
          throw new Error(errors as string);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const overwrites = (data as any).storyblok?.DatasourceEntries?.items || [];

        if (overwrites.length <= 0) {
          return;
        }

        fontFaceOverwrites[locale] = overwrites
          .reduce((accumulator, entry) => {
            const value = hasDimension ? entry.dimensionValue : entry.value;
            if (!value || value.trim() === 'default') {
              return accumulator;
            }

            return {
              ...accumulator,
              [entry.name]: value,
            };
          }, {});
      });

    await Promise.all(queryList.map((query) => query()));

    return Object.fromEntries(
      Object.entries(fontFaceOverwrites).filter((el) => Object.keys(el[1]).length > 0),
    ) as Record<string, Record<string, string>>;
  },

  /* Protects client only routes from matching slugs being created in Storyblok */
  clientOnlyPaths: {
    ch: ['/media/releases/', '/investors/updates/', '/test/releases/', '/topic/', '/worldwide/',
      '/medien-schweiz/informationen', '/fr/presse-suisse/informationen', '/en/media-switzerland/informationen'],
    com: ['/media/releases/', '/investors/updates/', '/test/releases/', '/topic/', '/worldwide/',
      '/medien-schweiz/informationen', '/fr/presse-suisse/informationen', '/en/media-switzerland/informationen'],
    default: ['/test/releases/', '/topic/', '/worldwide/',
      '/medien-schweiz/informationen', '/fr/presse-suisse/informationen', '/en/media-switzerland/informationen'],
  },

  isClientOnlyPath(path: string): boolean {
    return (
      // eslint-disable-next-line max-len
      (this.clientOnlyPaths[process.env.GATSBY_ROCHE_TOP_LEVEL_DOMAIN] || this.clientOnlyPaths.default)
        .some((clientOnlyPath) => `/${path}`.replace('//', '/').includes(clientOnlyPath))
    );
  },

  shouldSkipPageBuild(path: string): boolean {
    if (path.includes('[slug]') || path.endsWith('/')) {
      return false;
    }

    return this.isClientOnlyPath(path);
  },

  getExternalDataPagesList(): string[] {
    return this.externalDataPagesList[process.env.GATSBY_ROCHE_TOP_LEVEL_DOMAIN] || [];
  },

  externalDataPagesList: {
    ch: ['Component/en/media-switzerland/informationen/[slug]', 'Component/fr/presse-suisse/informationen/[slug]', 'Component/medien-schweiz/informationen/[slug]'],
    com: ['Component/media/releases/[slug]', 'Component/investors/updates/[slug]'],
  },

};
