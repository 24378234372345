export interface ContactFormData {
  regions: string[];
  subjects: string[];
}

export interface DropdownOption {
  label: string;
  value: string;
}

const contactFormaDataFilePath = '/contact-form-data/data.json';

export const FormService = {
  async fetchFormData(endpoint: string): Promise<Record<string, string>> {
    return fetch(endpoint)
      .then((result) => result.json(), () => ({}))
      .then(
        (data) => {
          sessionStorage.setItem(endpoint, JSON.stringify(data));
          return data;
        },
        () => ({}),
      );
  },
  async getContactFormData(): Promise<ContactFormData> {
    const contactFormData = JSON.parse(sessionStorage.getItem(`${contactFormaDataFilePath}`)) || await this.fetchFormData(contactFormaDataFilePath);
    return contactFormData || {};
  },
  createOptions(values: string[]): DropdownOption[] {
    return values.map((value) => ({ label: value, value }));
  },
};
