import { StringService } from '../string';

/**
 * This service allows to do client-side redirects at the start of the
 * application. Useful e.g. to redirect legacy routes.
 */
export const RedirectService = {
  /**
   * A list of redirect functions.
   *
   * A redirect function takes the current URL as an argument and returns either
   * a new URL that the user should be redirected to or `null` if no redirect
   * should be performed.
   */
  get redirects(): Array<(href: string) => string | null> {
    return (
      [this.redirectLegacyRoutes]
    );
  },

  /**
   * Helper function to modify a property in a URL string.
   *
   * Examples:
   *
   * replaceInURL('pathname', '/a/new/pathname', 'https://www.somesite.com/an/old/path?with=querystring&and#hash')
   *   === 'https://www.somesite.com/a/new/pathname?with=querystring&and#hash'
   *
   * replaceInURL('hash', '', 'https://www.somesite.com/an/old/path?with=querystring&and#hash')
   *   === 'https://www.somesite.com/an/old/path?with=querystring&and'
   */
  replaceInURL(property: string, value: string, href: string): string {
    const copyOfURL = new URL(href);

    copyOfURL[property] = value;

    return copyOfURL.href;
  },

  /**
   * Previous versions of the roche.com website used `.htm` and `.html`
   * extensions. In order to ensure backward compatibility, we redirect URLs
   * with those extensions to the canonical version without extension.
   */
  redirectLegacyRoutes(href: string): string | null {
    const url = new URL(href);
    const isLegacyURL = ['.htm', '.html'].some((extension) => url.pathname.endsWith(extension));

    if (isLegacyURL && !url.pathname.endsWith('index.html')) {
      const newPath = StringService.removeFileExtension(url.pathname);
      const newUrl = RedirectService.replaceInURL('pathname', newPath, url.href);

      return newUrl;
    }

    return null;
  },

  /**
   * Redirect to the first match in the `redirects` list.
   */
  redirectIfNecessary(): void {
    const newURL = this.redirects
      .map((redirect) => redirect(window.location.href))
      .find(Boolean);

    if (newURL) {
      window.location.replace(newURL);
    }
  },
};
