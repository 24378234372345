exports.components = {
  "component---src-pages-en-media-switzerland-informationen-[slug]-tsx": () => import("./../../../src/pages/en/media-switzerland/informationen/[slug].tsx" /* webpackChunkName: "component---src-pages-en-media-switzerland-informationen-[slug]-tsx" */),
  "component---src-pages-fr-presse-suisse-informationen-[slug]-tsx": () => import("./../../../src/pages/fr/presse-suisse/informationen/[slug].tsx" /* webpackChunkName: "component---src-pages-fr-presse-suisse-informationen-[slug]-tsx" */),
  "component---src-pages-investors-updates-[slug]-tsx": () => import("./../../../src/pages/investors/updates/[slug].tsx" /* webpackChunkName: "component---src-pages-investors-updates-[slug]-tsx" */),
  "component---src-pages-media-releases-[slug]-tsx": () => import("./../../../src/pages/media/releases/[slug].tsx" /* webpackChunkName: "component---src-pages-media-releases-[slug]-tsx" */),
  "component---src-pages-medien-schweiz-informationen-[slug]-tsx": () => import("./../../../src/pages/medien-schweiz/informationen/[slug].tsx" /* webpackChunkName: "component---src-pages-medien-schweiz-informationen-[slug]-tsx" */),
  "component---src-pages-preview-login-wall-tsx": () => import("./../../../src/pages/preview-login-wall.tsx" /* webpackChunkName: "component---src-pages-preview-login-wall-tsx" */),
  "component---src-pages-test-releases-[slug]-tsx": () => import("./../../../src/pages/test/releases/[slug].tsx" /* webpackChunkName: "component---src-pages-test-releases-[slug]-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */)
}

